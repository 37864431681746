// src/components/ProductPage.js
import React, { useState } from 'react';
import { Container, Card, Button, Row, Col, Form } from 'react-bootstrap';
import './ProductPage.css';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

const ProductPage = ({ product, onClose }) => {
  const [calendlyOpen, setCalendlyOpen] = useState(false);
  const [formData, setFormData] = useState({ name: '', email: '', phone: '' });

  const images = product.images || [];
  const [selectedImage, setSelectedImage] = useState(product.images[0]);
  
  const changeSelectedImage = (image) => {
    setSelectedImage(image);
  };

  const handleRequestMoreInfoClick = () => {
    setCalendlyOpen(true);
  };

  const handleCloseContactForm = () => {
    setCalendlyOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };
  
  return (
    <div className="product-page">
      <Container>
        <Card className="mb-4">
          
          <Card.Body>
            <Row>
              <Col md={6}>
                  {selectedImage && (
                    <div className="product-main-image">
                      <img src={selectedImage} alt={product.name} />
                    </div>
                  )}
                  {images.length > 0 && (
                    <div className="product-thumbnails">
                      {images.map((image, index) => (
                        <img
                          key={index}
                          src={image}
                          alt={product.name}
                          onClick={() => changeSelectedImage(image)}
                        />
                      ))}
                    </div>
                  )}
                </Col>
              <Col md={6} className="pt-5"> 
                <div className="d-flex justify-content-end">
                    <Button variant="primary" className='close-button' onClick={onClose} size="lg">
                        Close
                    </Button>
                </div>
                <Card.Title>{product.name}</Card.Title>
                <Card.Text>{product.description}</Card.Text>
                <br />
                <ul>
                  { product.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
                <Button variant="success" className='more-info-button' onClick={handleRequestMoreInfoClick} size="lg">
                    REQUEST MORE INFO
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
      {calendlyOpen && (
        <div className="contact-form-container">
          <Card>
            <Card.Header>Contact Us
            <Card.Text>
              Call us anytime at 844-694-8225!
            </Card.Text>
            </Card.Header>
            <Card.Body>
              <Form>
                <Form.Group controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Enter your name"
                  />
                </Form.Group>
                <Form.Group controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter your email"
                  />
                </Form.Group>
                <Form.Group controlId="formPhone">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Enter your phone"
                  />
                </Form.Group>
              </Form>
            </Card.Body>
            <Card.Footer>
              <Button
                variant="danger"
                className="contact-close"
                onClick={handleCloseContactForm}
              >
                Close Form
              </Button>
            </Card.Footer>
          </Card>
        </div>
      )}
    </div>
  );
};

export default ProductPage;
