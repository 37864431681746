import React from 'react';
import QCImage from '../Assets/PA_QC_9K_12K_18K_24K.png';
import QC2Zone18K from '../Assets/2Zone18K.webp';

const products = [
    {
        id: 1,
        productId: 1,
        productSku: '3PAMSHQC09',
        upc: '',
        name: 'Perfect Aire 9,000 BTU Quick Connect Mini Split System',
        description: 'This 9,000 BTU Quick Connect Mini-Split system from Perfect Aire delivers all-year comfort with none of the fuss that comes with traditional central AC systems.',
        category: 'Quick Connect',
        zoneType: 'Single Zone',
        availAce: false,
        activeStatus: true,
        features: [
            'You\'re in control of your comfort with 7 indoor fan speeds, adjustable vertical and horizontal swing louvers, a programmable 24-hour timer and convenient smart, super and auto functions.',
            'Sleek high-wall indoor air handler features a reusable and eco-friendly filter. Just slide-out and wash with soap and water — no need to purchase replacements.',
            'Control room temperature, set fan speeds and create programmable, intelligent modes for when you\'re at home or away, all from your mobile device with the convenient mobile app.',
            'Perfect for the at-home installer, this Perfect Aire Quick Connect Mini-Split includes revolutionary Quick Connect adapters for ultra-easy installation, no need to charge or evacuate refrigerant.',
            'No-hassle 1-year warranty: If a product fails within the first year of purchase due to a defect in materials or workmanship, the customer may return the unit to the store of purchase, along with their original purchase receipt, for an exchange of the same model.',
        ],
        price: 1089.99,
        installLowPrice: 1657.94,
        installHighPrice: 2486.91,
        installOnlyPrice: 350.00,
        image: QCImage,
        images: [
            QCImage,
            QCImage,
            QCImage,
            QCImage,
        ],
        },
    {
        id: 2,
        productId: 2,
        productSku: '3PAMSHQC12',
        upc: '',
        name: 'Perfect Aire 12,000 BTU Quick Connect Mini Split System',
        description: 'This 12,000 BTU Quick Connect Mini-Split system from Perfect Aire delivers all-year comfort with none of the fuss that comes with traditional central AC systems.',
        category: 'Quick Connect',
        zoneType: 'Single Zone',
        availAce: true,
        activeStatus: true,
        features: [
            'You\'re in control of your comfort with 7 indoor fan speeds, adjustable vertical and horizontal swing louvers, a programmable 24-hour timer and convenient smart, super and auto functions.',
            'Sleek high-wall indoor air handler features a reusable and eco-friendly filter. Just slide-out and wash with soap and water — no need to purchase replacements.',
            'Control room temperature, set fan speeds and create programmable, intelligent modes for when you\'re at home or away, all from your mobile device with the convenient mobile app.',
            'Perfect for the at-home installer, this Perfect Aire Quick Connect Mini-Split includes revolutionary Quick Connect adapters for ultra-easy installation, no need to charge or evacuate refrigerant.',
            'No-hassle 1-year warranty: If a product fails within the first year of purchase due to a defect in materials or workmanship, the customer may return the unit to the store of purchase, along with their original purchase receipt, for an exchange of the same model.',
        ],
        price: 1089.99,
        installLowPrice: 1718.41,
        installHighPrice: 3436.82,
        installOnlyPrice: 350.00,
        image: QCImage,
        images: [
            QCImage,
            QCImage,
            QCImage,
            QCImage,
        ],
        },
    {
        id: 3,
        productId: 3,
        productSku: '3PAMSHQC18',
        upc: '',
        name: 'Perfect Aire 18,000 BTU Quick Connect Mini Split System',
        description: 'This 18,000 BTU Quick Connect Mini-Split system from Perfect Aire delivers all-year comfort with none of the fuss that comes with traditional central AC systems.',
        category: 'Quick Connect',
        zoneType: 'Single Zone',
        availAce: false,
        activeStatus: true,
        features: [
            'You\'re in control of your comfort with 7 indoor fan speeds, adjustable vertical and horizontal swing louvers, a programmable 24-hour timer and convenient smart, super and auto functions.',
            'Sleek high-wall indoor air handler features a reusable and eco-friendly filter. Just slide-out and wash with soap and water — no need to purchase replacements.',
            'Control room temperature, set fan speeds and create programmable, intelligent modes for when you\'re at home or away, all from your mobile device with the convenient mobile app.',
            'Perfect for the at-home installer, this Perfect Aire Quick Connect Mini-Split includes revolutionary Quick Connect adapters for ultra-easy installation, no need to charge or evacuate refrigerant.',
            'No-hassle 1-year warranty: If a product fails within the first year of purchase due to a defect in materials or workmanship, the customer may return the unit to the store of purchase, along with their original purchase receipt, for an exchange of the same model.',
        ],
        price: 1089.99,
        installLowPrice: 1967.14,
        installHighPrice: 3934.28,
        installOnlyPrice: 350.00,
        image: QCImage,
        images: [
            QCImage,
            QCImage,
            QCImage,
            QCImage,
        ],
        },
    {
        id: 4,
        productId: 4,
        productSku: '3PAMSHQC24',
        upc: '',
        name: 'Perfect Aire 24,000 BTU Quick Connect Mini Split System',
        description: 'This 24,000 BTU Quick Connect Mini-Split system from Perfect Aire delivers all-year comfort with none of the fuss that comes with traditional central AC systems.',
        category: 'Quick Connect',
        zoneType: 'Single Zone',
        availAce: true,
        activeStatus: true,
        features: [
            'You\'re in control of your comfort with 7 indoor fan speeds, adjustable vertical and horizontal swing louvers, a programmable 24-hour timer and convenient smart, super and auto functions.',
            'Sleek high-wall indoor air handler features a reusable and eco-friendly filter. Just slide-out and wash with soap and water — no need to purchase replacements.',
            'Control room temperature, set fan speeds and create programmable, intelligent modes for when you\'re at home or away, all from your mobile device with the convenient mobile app.',
            'Perfect for the at-home installer, this Perfect Aire Quick Connect Mini-Split includes revolutionary Quick Connect adapters for ultra-easy installation, no need to charge or evacuate refrigerant.',
            'No-hassle 1-year warranty: If a product fails within the first year of purchase due to a defect in materials or workmanship, the customer may return the unit to the store of purchase, along with their original purchase receipt, for an exchange of the same model.',
        ],
        price: 1089.99,
        installLowPrice: 2290.69,
        installHighPrice: 4581.39,
        installOnlyPrice: 350.00,
        image: QCImage,
        images: [
            QCImage,
            QCImage,
            QCImage,
            QCImage,
        ],
        },
    {
        id: 5,
        productId: 5,
        productSku: '3PAMSHQC36-2',
        upc: '',
        name: 'Perfect Aire 36,000 BTU Quick Connect Mini Split System',
        description: 'This 36,000 BTU Quick Connect Mini-Split system from Perfect Aire delivers all-year comfort with none of the fuss that comes with traditional central AC systems.',
        category: 'Quick Connect',
        zoneType: 'Single Zone',
        availAce: false,
        activeStatus: true,
        features: [
            'You\'re in control of your comfort with 7 indoor fan speeds, adjustable vertical and horizontal swing louvers, a programmable 24-hour timer and convenient smart, super and auto functions.',
            'Sleek high-wall indoor air handler features a reusable and eco-friendly filter. Just slide-out and wash with soap and water — no need to purchase replacements.',
            'Control room temperature, set fan speeds and create programmable, intelligent modes for when you\'re at home or away, all from your mobile device with the convenient mobile app.',
            'Perfect for the at-home installer, this Perfect Aire Quick Connect Mini-Split includes revolutionary Quick Connect adapters for ultra-easy installation, no need to charge or evacuate refrigerant.',
            'No-hassle 1-year warranty: If a product fails within the first year of purchase due to a defect in materials or workmanship, the customer may return the unit to the store of purchase, along with their original purchase receipt, for an exchange of the same model.',
        ],
        price: 1089.99,
        installLowPrice: 2460.11,
        installHighPrice: 4920.22,
        installOnlyPrice: 350.00,
        image: QCImage,
        images: [
            QCImage,
            QCImage,
            QCImage,
            QCImage,
        ],
        },
    {
        id: 6,
        productId: 6,
        productSku: '3PAMSHHQC-MZ2-9-9',
        upc: '',
        name: 'Perfect Aire 18,000 BTU Multi-Zone Quick Connect System - 9K & 9K HEADS',
        description: 'This 18,000 BTU Quick Connect Mini-Split system from Perfect Aire delivers all-year comfort with none of the fuss that comes with traditional central AC systems.',
        category: 'Quick Connect',
        zoneType: '2-Zone',
        availAce: false,
        activeStatus: true,
        features: [
            'You\'re in control of your comfort with 7 indoor fan speeds, adjustable vertical and horizontal swing louvers, a programmable 24-hour timer and convenient smart, super and auto functions.',
            'Sleek high-wall indoor air handler features a reusable and eco-friendly filter. Just slide-out and wash with soap and water — no need to purchase replacements.',
            'Control room temperature, set fan speeds and create programmable, intelligent modes for when you\'re at home or away, all from your mobile device with the convenient mobile app.',
            'Perfect for the at-home installer, this Perfect Aire Quick Connect Mini-Split includes revolutionary Quick Connect adapters for ultra-easy installation, no need to charge or evacuate refrigerant.',
            'No-hassle 1-year warranty: If a product fails within the first year of purchase due to a defect in materials or workmanship, the customer may return the unit to the store of purchase, along with their original purchase receipt, for an exchange of the same model.',
        ],
        price: 1089.99,
        installLowPrice: 3341.40,
        installHighPrice: 6682.81,
        installOnlyPrice: 350.00,
        image: QCImage,
        images: [
            QCImage,
            QCImage,
            QCImage,
            QCImage,
        ],
        },
    {
        id: 7,
        productId: 7,
        productSku: '3PAMSHHQC-MZ2-9-12',
        upc: '',
        name: 'PERFECT AIRE 21,000 BTU MULTI-ZONE QUICK CONNECT SYSTEM - 9K & 12K HEADS',
        description: 'This 9,000 BTU Quick Connect Mini-Split system from Perfect Aire delivers all-year comfort with none of the fuss that comes with traditional central AC systems.',
        category: 'Quick Connect',
        zoneType: 'Single Zone',
        availAce: false,
        activeStatus: true,
        features: [
            'You\'re in control of your comfort with 7 indoor fan speeds, adjustable vertical and horizontal swing louvers, a programmable 24-hour timer and convenient smart, super and auto functions.',
            'Sleek high-wall indoor air handler features a reusable and eco-friendly filter. Just slide-out and wash with soap and water — no need to purchase replacements.',
            'Control room temperature, set fan speeds and create programmable, intelligent modes for when you\'re at home or away, all from your mobile device with the convenient mobile app.',
            'Perfect for the at-home installer, this Perfect Aire Quick Connect Mini-Split includes revolutionary Quick Connect adapters for ultra-easy installation, no need to charge or evacuate refrigerant.',
            'No-hassle 1-year warranty: If a product fails within the first year of purchase due to a defect in materials or workmanship, the customer may return the unit to the store of purchase, along with their original purchase receipt, for an exchange of the same model.',
        ],
        price: 1089.99,
        installLowPrice: 3369.13,
        installHighPrice: 6738.26,
        installOnlyPrice: 350.00,
        image: QCImage,
        images: [
            QCImage,
            QCImage,
            QCImage,
            QCImage,
        ],
        },
    {
        id: 8,
        productId: 8,
        productSku: '3PAMSHHQC-MZ2-12-12',
        upc: '',
        name: 'Perfect Aire 24,000 BTU Multi-Zone Quick Connect System - 12K & 12K HEADS',
        description: 'This 9,000 BTU Quick Connect Mini-Split system from Perfect Aire delivers all-year comfort with none of the fuss that comes with traditional central AC systems.',
        category: 'Quick Connect',
        zoneType: 'Single Zone',
        availAce: false,
        activeStatus: true,
        features: [
            'You\'re in control of your comfort with 7 indoor fan speeds, adjustable vertical and horizontal swing louvers, a programmable 24-hour timer and convenient smart, super and auto functions.',
            'Sleek high-wall indoor air handler features a reusable and eco-friendly filter. Just slide-out and wash with soap and water — no need to purchase replacements.',
            'Control room temperature, set fan speeds and create programmable, intelligent modes for when you\'re at home or away, all from your mobile device with the convenient mobile app.',
            'Perfect for the at-home installer, this Perfect Aire Quick Connect Mini-Split includes revolutionary Quick Connect adapters for ultra-easy installation, no need to charge or evacuate refrigerant.',
            'No-hassle 1-year warranty: If a product fails within the first year of purchase due to a defect in materials or workmanship, the customer may return the unit to the store of purchase, along with their original purchase receipt, for an exchange of the same model.',
        ],
        price: 1089.99,
        installLowPrice: 3396.86,
        installHighPrice: 6793.71,
        installOnlyPrice: 350.00,
        image: QCImage,
        images: [
            QCImage,
            QCImage,
            QCImage,
            QCImage,
        ],
        }
  ];
  
  export default products;
  