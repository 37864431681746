import React from 'react';
import { Button, Navbar, Container, Nav } from 'react-bootstrap';
import logo from '../Assets/ProAireLogo_Blue.png';
import palogo from '../Assets/PAlogo.svg';
import acelogo from '../Assets/Ace_Hardware_Logo.png';
import '../App.css';

const Header = () => {

  return (
    <Navbar className="Navbar" expand="lg">
      <Container>
        <Navbar.Brand href="./">
          <img
            src={acelogo}
            width="auto"
            height="75"
            className="d-inline-block align-top"
            alt="Perfect Aire Logo"
          />
          <img
            src={palogo}
            width="auto"
            height="75"
            className="d-inline-block align-top navbar-img1"
            alt="Pro Aire Logo"
          />
          {' '}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Button href="./" variant="warning" size="lg">Home</Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
