// src/components/ProductCard.js
import React from 'react';
import { Card, Button } from 'react-bootstrap';
import "../App.css";

const ProductCard = ({ product, onClick }) => {
  return (
    <Card className='prod-card-container text-center' onClick={() => onClick(product)}>
      <Card.Img variant="top" src={product.image} />
      <Card.Body>
        <Card.Title>{product.name}</Card.Title>
      </Card.Body>
    </Card>
  );
};

export default ProductCard;
