import React from 'react';
import { Card } from 'react-bootstrap';
import "../App.css";

const ProductCarouselCard = ({ product, onClick }) => {
  return (
    <Card className='carousel-product-card' onClick={() => onClick(product)}>
      <div className='card-image-container'>
        <Card.Img variant="top" src={product.image} />
      </div>
      <Card.Body>
        <Card.Title>{product.name}</Card.Title>
        <p className={`availability-text ${product.availAce ? 'ace-store' : 'dropship'}`}>
          {product.availAce ? 'Available at Your Local Ace Hardware Store' : 'Available for Dropship'}
        </p>
      </Card.Body>
    </Card>
  );
};

export default ProductCarouselCard;
