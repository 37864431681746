import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ProductCard from './ProductCard';
import ProductPage from './ProductPage';
import ProductCarousel from './ProductCarousel';
import { allProducts } from '../Data/productArrays'

const ProductCatalog = ({ products }) => {
    const [selectedProduct, setSelectedProduct] = useState(null);

    const handleProductClick = (product) => {
        setSelectedProduct(product);
    };

    const closeProductPage = () => {
        setSelectedProduct(null);
    };

    return (
        <>
            {selectedProduct ? (
                <ProductPage product={selectedProduct} onClose={closeProductPage} />
            ) : (
                <Container>
                    <Row> 
                        <ProductCarousel 
                            products={ allProducts }
                            onClick={ handleProductClick } 
                        />
                    </Row>
                    
                </Container>
            )}
        </>
    );
};

export default ProductCatalog;
