// src/components/ProductGuide.js
import React, { useState } from 'react';
import { Container, Form, Button, Card } from 'react-bootstrap';
import GuideQuestions from './GuideQuestions';
import RecommendedProduct from './RecommendedProduct';

const ProductGuide = () => {
    const [question, setQuestion] = useState(1);
    const [answer1, setAnswer1] = useState('');
    const [answer2, setAnswer2] = useState(Array(3).fill('')); // Initialize answer2 as an array
    const [showResult, setShowResult] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (question === 1) {
          setQuestion(2);
        } else {
          setShowResult(true);
        }
    };

    const handleBack = () => {
        setQuestion(1);
    };

    const resetGuide = () => {
      setQuestion(1);
      setAnswer1('');
      setAnswer2(Array(3).fill(''));
      setShowResult(false);
    };
    
    /*
    const recommendedProduct = {
        id: 1,
        name: 'Recommended Product',
        image: '',
        description: 'This is a recommended product based on your answers.'
    };
    */
    
    return (
        <Container>
          <div className='guide-title'></div>
          <>
          {!showResult ? (
            <GuideQuestions
              question={question}
              answer1={answer1}
              answer2={answer2}
              setAnswer1={setAnswer1}
              setAnswer2={setAnswer2}
              handleSubmit={handleSubmit}
              handleBack={handleBack}
            />
          ) : (
            <>
            <RecommendedProduct answer1={answer1} answer2={answer2} />
            <div className='mt-3'>
              <Button variant="danger" onClick={resetGuide}>
                Reset Guide
              </Button>
            </div>
            </>
            )}
          </>
        </Container>
      );
};

export default ProductGuide;
