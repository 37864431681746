import './App.css';
import React, { useState, useContext } from 'react';
import Header from './Components/Header';
import Footer from './Components/Footer';
import MainScreen from "./Components/MainScreen";
import ProductCatalog from "./Components/ProductCatalog";
import ProductGuide from "./Components/ProductGuide";
import products from './Data/products';
import { AppContext } from './Helpers/Contexts';


function App() {
  // const basename = document.querySelector('base')?.getAttribute('href') ?? '/'
  const [appState, setAppState] = useState("main");



  return (
    <div className="App">
      <Header />

      <AppContext.Provider value={{ appState, setAppState }}>
        {appState === "main" && <MainScreen />}
        {appState === "catalog" && <ProductCatalog products={products} />}
        {appState === "guide" && <ProductGuide />}
      </AppContext.Provider>

      <Footer />
    </div>
  );
}

export default App;
