import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import '../App.css';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import axios from 'axios';

const ContactForm = ({ handleBack }) => {
    const handleSubmit = async (e) => {
        e.preventDefault();

        const name = e.target.name.value;
        const email = e.target.email.value;
        const phone = e.target.phone.value;
        const message = e.target.message.value;

        try {
            const formData = new FormData();
            formData.append('name', name);
            formData.append('email', email);
            formData.append('phone', phone);
            formData.append('message', message);

            await axios.post('https://proaire.me/send_email.php', formData);
            alert('Email sent successfully.');
        } catch (error) {
            alert('Failed to send email.');
            console.error(error);
        }
    };

    const [inputValues, setInputValues] = useState({
        name: '',
        phone: '',
        email: '',
        message: '',
    });

    const [inputFieldName, setInputFieldName] = useState(null);

    const onKeyboardChange = (input) => {
        setInputValues((prevValues) => ({
            ...prevValues,
            [inputFieldName]: input,
        }));
    };

    const handleInputFocus = (e) => {
        setInputFieldName(e.target.name);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputValues((prevValues) => ({
        ...prevValues,
        [name]: value,
        }));
    };

    return (
        <Form onSubmit={ handleSubmit }>
            <Form.Group className='form-inline'>
                <Form.Label>Name</Form.Label>
                <Form.Control 
                    name="name"
                    type="text" 
                    placeholder="Enter your name" 
                    inputMode='text' 
                    value={inputValues.name}
                    onFocus={handleInputFocus}
                    onChange={handleInputChange}
                />
            </Form.Group>
            <Form.Group className='form-inline'>
                <Form.Label>Phone</Form.Label>
                <Form.Control 
                    name="phone"
                    type="tel" 
                    placeholder="Enter your number" 
                    inputMode='text' 
                    value={inputValues.phone}
                    onFocus={handleInputFocus}
                    onChange={handleInputChange}
                />
            </Form.Group>
            <Form.Group className='form-inline'>
                <Form.Label>Email</Form.Label>
                <Form.Control 
                    name="email"
                    type="email" 
                    placeholder="Enter your email" 
                    inputMode='text' 
                    value={inputValues.email}
                    onFocus={handleInputFocus}
                    onChange={handleInputChange}
                />
            </Form.Group>
            <Form.Group className='form-inline'>
                <Form.Label>Message</Form.Label>
                <Form.Control 
                    name="message"
                    type="textarea" 
                    placeholder="Write us a note" 
                    inputMode='text' 
                    value={inputValues.message}
                    onFocus={handleInputFocus}
                    onChange={handleInputChange}
                />
            </Form.Group>
            <Keyboard
                onChange={onKeyboardChange}
                inputName={inputFieldName}
                useMouseEvents={true}
            />
            <Button variant='primary' className='send-button' type="submit">
                Send
            </Button>
            <Button variant='secondary' onClick={handleBack} className='send-button'>
                Back
            </Button>
        </Form> 
    );
};

export default ContactForm;