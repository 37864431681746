import React, { useContext } from "react";
import { AppContext } from "../Helpers/Contexts";
import "../App.css";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import QCCardBG from '../Assets/PA_QC_9K_12K_18K_24K.png';

export default function MainScreen() {
    const { appState, setAppState } = useContext(AppContext);

    return (
        <Container>
            <Row className="mt-5">
                <Col sm={12} md={6} className="mb-4">
                    <Card 
                        className="h-100"
                        onClick={
                            () => {
                                setAppState("catalog");
                            }}>
                        <div className="card-image-wrapper">
                            <Card.Img variant="top" src={QCCardBG} />
                            <div className="card-overlay">
                                <Card.Title>Product Catalog</Card.Title>
                                <Card.Text>Browse our collection of Mini-Splits</Card.Text>
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col sm={12} md={6} className="mb-4">
                    <Card 
                        className="h-100"
                        onClick={
                            () => {
                                setAppState("guide");
                            }}>
                        <div className="card-image-wrapper">
                            <Card.Img variant="top" src={QCCardBG} />
                            <div className="card-overlay">
                                <Card.Title>Model Selector</Card.Title>
                                <Card.Text>Take a short survey to find the right Mini-Split</Card.Text>
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>

        );
    }