// src/components/ProductCard.js
import React from 'react';
import { Container, Card, Button, Row, Col } from 'react-bootstrap';
import CalendlyWidget from './CalendlyWidget';
import '../App.css';
import { formatCurrency } from '../Helpers/Utils';
import proairelogo from '../Assets/ProAireLogo_Blue.png'

const ProductRecCard = ({ product }) => {
    const lowPrice = formatCurrency(product.installLowPrice);
    const highPrice = formatCurrency(product.installHighPrice);

    return (
        <Container>
            <Row className=''>
                <Col md={6} className=''>
                    <Card className='h-100 mb-4 left-card'>
                        <Card.Body>
                            <Card.Title>{product.name}</Card.Title>
                            <br />
                            <Card.Text>{product.description}</Card.Text>
                        </Card.Body>
                        <Card.Img variant="top" src={product.image} />
                    </Card> 
                </Col>
                <Col md={6} className=''>
                    <Card className='mb-3'>
                        <Card.Body>
                            <Row>
                                <Col>
                                <Card.Text className='estimate-text'>
                                    Get a FREE installation estimate from Perfect Aire's CERTIFIED LOCAL INSTALLER:
                                    <div className='installer-name'>Pro Aire HVAC Services.</div>
                                </Card.Text>
                                </Col>
                                <Col xs="auto">
                                    <img src={proairelogo} alt="Pro Aire HVAC Services" style={{ height: '75px', width: 'auto'}} />
                                </Col>
                            </Row>
                            <Row className='estimate-price'>
                                <Card.Title>
                                    Estimated Total Cost: { lowPrice } - { highPrice }
                                </Card.Title>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card className=''>
                        <Card.Body>
                            <Card.Title> Schedule a FREE estimate! </Card.Title>
                            <CalendlyWidget />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>        
  );
};

export default ProductRecCard;
