import React, { useRef } from "react";
import { Carousel, Row, Col } from "react-bootstrap";
import ProductCarouselCard from "./ProductCarouselCard";
import '../App.css';

const ProductCarousel = ({ products, onClick }) => {
    const chunkedProducts = chunk(products, 3);

    const renderProductCard = (product) => {
        if (product === null) {
          // Return an empty div with the same class as the product card
          return (
            <div
              className="carousel-product-card"
              key={`empty-placeholder-${Math.random()}`}
            ></div>
          );
        }
        return (
          <ProductCarouselCard
            key={product.id}
            product={product}
            onClick={onClick}
            className="carousel-product-card"
          />
        );
      };
      

    const renderCarouselItem = (chunk, index) => (
        <Carousel.Item key={index}>
            <div className="carousel-inner">
                {chunk.map(renderProductCard)}
            </div>
        </Carousel.Item>
    );

    const carouselRef = useRef(null);

    return (
        <div className="carousel-container">
            <Row>
                <Col xs="auto" className="carousel-button-align">
                    <button
                        className="carousel-control-prev btn"
                        onClick={() => carouselRef.current.prev()}
                    >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="60"
                          height="60"
                          fill="currentColor"
                          className="carousel-control-prev-icon bi bi-chevron-left"
                          viewBox="0 0 16 16"
                          style={{ transform: "scale(2)", marginBottom: "10px" }}
                        >
                          <path
                            fill-rule="evenodd"
                            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                          />
                        </svg>
                    </button>
                </Col>
                <Col>
                <Carousel
                    ref={carouselRef}
                    interval={null}
                    controls={false}
                >
                    {chunkedProducts.map(renderCarouselItem)}
                </Carousel>
                </Col>
                <Col xs="auto" className="carousel-button-align">
                    <button
                        className="carousel-control-next btn"
                        onClick={() => carouselRef.current.next()}
                    >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="60"
                          height="60"
                          fill="currentColor"
                          className="carousel-control-next-icon bi bi-chevron-right"
                          viewBox="0 0 16 16"
                          style={{ transform: "scale(2)", marginBottom: "10px" }}
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                          />
                        </svg>
                    </button>
                </Col>
            </Row>
        </div>
    );
};

const chunk = (array, size) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArray.push(array.slice(i, i + size));
    }
    const lastChunk = chunkedArray[chunkedArray.length - 1];
    while (lastChunk.length < size) {
      lastChunk.push(null);
    }
  
    return chunkedArray;
  };

export default ProductCarousel;