import products from "./products";

export const productsAce = products.filter(
    (product) => product.availAce === true
);

export const productsNotAce = products.filter(
    (product) => product.availAce === false
);

export const allProducts = products.filter(
    (product) => product.activeStatus === true
);