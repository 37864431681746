// src/components/GuideQuestions.js
import React, { useState } from 'react';
import { Form, Button, Row, Col, ButtonGroup } from 'react-bootstrap';
import '../App.css';
import zonesimg from '../Assets/zonesimg.png';
import sizingimg from '../Assets/sizingimg.png';
import ContactForm from './ContactForm';

const GuideQuestions = ({
  question,
  answer1,
  answer2,
  setAnswer1,
  setAnswer2,
  handleSubmit,
  handleBack,
}) => {

  const [showContactForm, setShowContactForm] = useState(false);

  const images = [
    zonesimg,
    sizingimg,
    '3Zone.png',
  ];

  const handleContactClick = () => {
    setShowContactForm(true);
  };

  const handleBackFromContact = () => {
    setShowContactForm(false);
  };

  const renderRoomDropdowns = (numberOfRooms) => {
    const roomOptions = [];

    for (let i = 0; i < numberOfRooms; i++) {
      roomOptions.push(
        <Form.Group key={i} className='formGroup'>
          <Form.Label className='formLabel'>{`Room ${i + 1}`}</Form.Label>
          <Form.Control
            as="select"
            value={answer2[i]}
            onChange={(e) => {
              const newAnswer2 = [...answer2];
              newAnswer2[i] = e.target.value;
              setAnswer2(newAnswer2);
            }}
          >
            <option value="">Choose...</option>
            <option value="375-499">375 - 499 Sq Ft</option>
            <option value="500-749">500 - 749 Sq Ft</option>
            <option value="750-999">750 - 999 Sq Ft</option>
            <option value="1000-1499">1000 - 1499 Sq Ft</option>
            <option value="1500+">1500+ Sq Ft</option>
          </Form.Control>
        </Form.Group>
      );
    }

    return roomOptions;
  };

  return (
    <>
    {showContactForm ? (
      <ContactForm handleBack={handleBackFromContact} />
    ) : (
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            {question === 1 ? (
              <>
                <Form.Group className='formGroup'>
                  <Row>
                    <Form.Label className='formQuestion'>How many rooms (zones) do you need?</Form.Label>
                  </Row>
                  <Row className='button-row'>
                    <Col xs={4}>
                      <Button className="wide-button" variant="outline-primary" onClick={() => setAnswer1("1")} active={answer1 === "1" }>
                        1
                      </Button>
                    </Col>
                    <Col xs={4}>
                      <Button className="wide-button" variant="outline-primary" onClick={() => setAnswer1("2")} active={answer1 === "2" }>
                        2
                      </Button>
                    </Col>
                    <Col xs={4}>
                      <Button className="wide-button" variant="outline-primary" onClick={() => setAnswer1("3")} active={answer1 === "3" }>
                        3
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mt-3 button-row">
                    <Col xs={4}>
                      <Button className="wide-button" variant="outline-primary" onClick={() => setAnswer1("4")} active={answer1 === "4" }>
                        4
                      </Button>
                    </Col>
                    <Col xs={4}>
                      <Button className="wide-button" variant="outline-primary" onClick={() => setAnswer1("5")} active={answer1 === "5" }>
                        5
                      </Button>
                    </Col>
                    <Col xs={4}>
                      <Button className="wide-button" variant="outline-primary" onClick={() => setAnswer1("More")} active={answer1 === "More" }>
                        More
                      </Button>
                    </Col>
                  </Row>
                </Form.Group>
                <Button variant="primary" type="submit" size='lg' className='form-nav-button'>
                  Next
                </Button>
              </>
            ) : (
              <>
                <Form.Group className='formGroup'>
                  <Form.Label className='formQuestion'>
                    What are your room sizes?
                  </Form.Label>
                </Form.Group>
                
                {renderRoomDropdowns(parseInt(answer1))}
                <Row>
                  <Col>
                    <Button variant="secondary" onClick={handleBack} className="form-nav-button w-100">
                      Back
                    </Button>
                  </Col>
                  <Col>
                    <Button variant="primary" type="submit" className='form-nav-button w-100'>
                      Submit
                    </Button>
                  </Col>
                </Row>
                <Button 
                  variant='danger' 
                  onClick={handleContactClick} 
                  className='m1-2 form-nav-button contact-button'
                >
                  Unsure? Contact Us!
                </Button>
              </>
            )}
          </Col>
          <Col md={6}>
            <img
              src={images[question - 1]}
              alt={`Image for question ${question}`}
              className="img-fluid"
            />
          </Col>
        </Row>
      </Form>
    )}
   </> 
  );
};

export default GuideQuestions;
