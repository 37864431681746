// src/components/RecommendedProduct.js
import React from 'react';
import ProductRecCard from './ProductRecCard';
import products from '../Data/products';
import { Button, Card, Col, Row } from 'react-bootstrap';
import getProductRecommendation from './ProductRules';
import { InlineWidget } from 'react-calendly';
import aceQR from '../Assets/ace-palos-schedule.png';
import '../App.css';

const RecommendedProduct = ({ answer1, answer2 }) => {
    const productId = getProductRecommendation(answer1, answer2);
    const product = products.find((item) => item.id === productId);

    if (!product) {
        return (
            <Card>
              <Card.Body>
                <Row>
                  <Col>
                    <p className='qr-text'>
                      We need additional details to give you an accurate quote. 
                      <br/> <br/>
                      Please scan the QR code below to book a FREE Estimate with our HVAC Partner's Comfort Advisors, or schedule in the calendar to the right.
                      <br/> <br/>
                      Or CALL/TEXT: 844-694-8225
                    </p>
                    <img
                        src={aceQR}
                        alt="QR to schedule free estimate at Ace Palos store"
                        className='mx-auto d-block qr-image'
                    />
                  </Col>
                  <Col>
                    <InlineWidget url="https://calendly.com/proairehvac/more-info" />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          );
        }
    return (
        <div>
            <ProductRecCard product={product} />
        </div>
    );

};



export default RecommendedProduct;
