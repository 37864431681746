import React from 'react';
import '../App.css';

const Footer = () => {

  return (
      <footer className='footer'>
        <div className='container'>
            <p>
                &copy; {new Date().getFullYear()} Perfect Aire
            </p>
        </div>
      </footer>
  );
};

export default Footer;
