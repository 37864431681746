const getProductRecommendation = (answer1, answer2) => {
    // Option 1
    if (answer1 === '1' && answer2[0] === '375-499') {
        return 1;
    }
    if (answer1 === '1' && answer2[0] === '500-749') {
        return 1;
    }
    if (answer1 === '1' && answer2[0] === '750-999') {
        return 1;
    }
    if (answer1 === '1' && answer2[0] === '1000-1499') {
        return 1;
    }
    if (answer1 === '1' && answer2[0] === '1500+') {
        return 1;
    }
    if (answer1 === '2' && answer2[0] === '375-499' && answer2[1] === '500-749') {
        return 6;
    }
    if (answer1 === '2' && answer2[0] === '375-499') {
        return 1;
    }
    if (answer1 === '2' && answer2[0] === '375-499') {
        return 1;
    }
    if (answer1 === '3' && answer2[0] === '375-499' && answer2[1] === '500-749' && answer2[2] === '750-999') {
        return 1;
    }
    if (answer1 === '2' && answer2[0] === '375-499') {
        return 1;
    }

    return null;
};

export default getProductRecommendation;